import React, {ReactNode, useEffect, useState} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Table, Title, Select } from "@mantine/core";
import { DeliveryCostDto, DeliveryCostListResponse } from "../../dto/DeliveryCostDto";
import { DeliveryCostService } from "../../services/DeliveryCostService";
import { REGION_CODE_LABELS } from "../../constants/RegionCode";

const LIST_PAGE_LIMIT = 10;

const region = [
  { value: "", label: "전체"},
  { value: "SEOUL", label: "서울" },
  { value: "INCHEON", label: "인천" },
  { value: "GYEONGGI_S", label: "경기남부" },
  { value: "GYEONGGI_N", label: "경기북부" },
  { value: "JEJU", label: "제주"},
];

export default function DeliveryCostListPage() {
  const navigate = useNavigate();
  const [regionCode, setRegionCode] = useState<string>(region[0].value);
  const [items, setItems] = useState<DeliveryCostDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    DeliveryCostService.getList(regionCode, currentPage, LIST_PAGE_LIMIT).then((response) => {
      const result = response.data as DeliveryCostListResponse;
      setRegionCode(regionCode)
      setItems(result.items);
      setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
      console.log(result);
    });
  }, [currentPage, regionCode]);

  useEffect(() => {
    setCurrentPage(1);
  }, [regionCode]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const onClickTable = (deliveryCostId: number) => {
    navigate("" + deliveryCostId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{REGION_CODE_LABELS[item.region_code]}</td>
      <td>{item.name}</td>
      <td>{item.oneway_cost}</td>
      <td>{item.return_cost}</td>
      <td>{item.first_postcode}</td>
      <td>{item.last_postcode}</td>
      <td>{item.active ? "활성" : "비활성"}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>가격</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="space-between" align="center" wrap="wrap">
        <Select
          value={regionCode}
          placeholder="지역을 선택 해주세요."
          data={region}
          onChange={(event) => setRegionCode(event!)}
        />
        <Button onClick={onClickCreate}>등록하기</Button>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
        <tr>
          <th>ID</th>
          <th>지역</th>
          <th>이름</th>
          <th>편도 가격</th>
          <th>왕복 가격</th>
          <th>우편번호 시작</th>
          <th>우편번호 끝</th>
          <th>활성여부</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
`;
