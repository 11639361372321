import {
  Button,
  Grid,
  Group,
  Radio,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useParams } from "react-router-dom";
import { PlaceService } from "../../services/PlaceService";
import { CommonResponse } from "../../dto/CommonDto";
import { RESULT_CODE } from "../../constants/ResultCode";
import { PlaceDetailDto } from "../../dto/PlaceDto";

export default function PlaceDetailPage() {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const { placeId } = useParams();
  // const { state: { role } } = useContext(AuthContext);
  const [currentId, setCurrentId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [active, setActive] = useState<string>("true");

  useEffect(() => {
    const id = parseInt(placeId!);
    setCurrentId(id);

    if (id > 0) {
      PlaceService.detail(id).then((response) => {
        const result = response.data as PlaceDetailDto;
        if (result) {
          setName(result.name);
          setPostcode(result.postcode);
          setAddress(result.address);
          setActive("" + result.active);
        }
        console.log(result);
      });
    }
  }, []);


  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (name === "" || postcode === "") {
      alert("장소명 또는 주소를 확인해주세요.");
      return
    }

    PlaceService.save({
      id: currentId,
      name: name,
      postcode: postcode,
      address: address,
      active: active === "true"
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    PlaceService.deletePlace(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const handlePopup = () => {
    setPopup(!popup);
  }

  const handleComplete = (data: any) => {
    setPostcode(data.zonecode);
    setAddress(data.address);
  }


  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          <Button onClick={onClickDelete}>삭제하기</Button>
          {/*{role === USER_ROLE.ADMIN ? (*/}
          {/*  <Button onClick={onClickDelete}>삭제하기</Button>*/}
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <TextInput
        label="장소(골프장)"
        placeholder="장소 이름을 입력하세요"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="xl" />
      <Grid align="flex-end">
        <Grid.Col span={2}>
          <TextInput
            label="우편번호"
            disabled={true}
            value={postcode}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput
            label="주소"
            disabled={true}
            value={address}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Button fullWidth onClick={handlePopup}>주소찾기</Button>
        </Grid.Col>
        <Grid.Col span={12}>{popup && <span><DaumPostcode submitMode={false} onComplete={handleComplete} /></span>}</Grid.Col>
      </Grid>
      <Space h="xl" />
      <Radio.Group value={active} onChange={setActive} label="활성여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}