import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Pagination, Space, Table, Title } from "@mantine/core";
import { UserAddressService } from "../../services/UserAddressService";
import {UserAddressDto, UserAddressListResponse} from "../../dto/UserAddressDto";

const LIST_PAGE_LIMIT = 10;
export default function UserAddressListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState<UserAddressDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const param = { ...location.state };
    UserAddressService.getList(param.userId, currentPage, LIST_PAGE_LIMIT).then((response) => {
      const result = response.data as UserAddressListResponse;
      setItems(result.items);
      setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
      console.log(result);
    });
  }, [currentPage]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickTable = (userAddressId: number) => {
    navigate("" + userAddressId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.name}</td>
      <td>{item.phone}</td>
      <td>{item.postcode}</td>
      <td>{item.address0}</td>
      <td>{item.address1}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>회원 주소</Title>
      <Space h="xl" />
        <Button color="gray" onClick={onClickBack}>회원 목록으로</Button>
      <Space h="xl" />
      <Table>
        <thead>
        <tr>
          <th>이름</th>
          <th>휴대번호</th>
          <th>우편번호</th>
          <th>주소</th>
          <th>상세 주소</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
`;
