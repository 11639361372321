import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  Pagination,
  Select,
  Space,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import { UserItemDto, UserListResponse } from "dto/UserDto";
import { UserService } from "services/UserService";

interface State {
  page: number;
  type: string;
  text: string;
}

const searchTypes = [
  { value: "name", label: "회원명" },
  { value: "email", label: "이메일" },
  { value: "phone", label: "휴대번호" },
];

const LIST_PAGE_LIMIT = 10;
export default function UserListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<UserItemDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchType, setSearchType] = useState<string>(searchTypes[0].value);
  const [searchText, setSearchText] = useState<string>("");
  const [state, setState] = useState<State>();

  useEffect(() => {
    let temp = state
      ? { ...state }
      : {
          page: 1,
          status: "",
          type: searchTypes[0].value,
          text: "",
        };

    const page = searchParams.get("page");
    if (page !== null) {
      temp.page = parseInt(page);
    }

    const type = searchParams.get("type");
    if (type !== null) {
      temp.type = type;
      setSearchType(type);
    }

    const text = searchParams.get("text");
    if (text !== null) {
      temp.text = text;
      setSearchText(text);
    } else {
      setSearchText("");
    }

    setState(temp);
  }, [searchParams]);

  useEffect(() => {
    if (state) {
      UserService.userList(
        state.type,
        state.text,
        state.page,
        LIST_PAGE_LIMIT
      ).then((response) => {
        const result = response.data as UserListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      });
    }
  }, [state]);

  const changePage = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: "" + page });
  };

  const handleSearch = () => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...params,
      page: "1",
      type: searchType,
      text: searchText,
    });
  };

  const onClickTable = (userId: number) => {
    navigate("" + userId);
  };

  const onClickAddress = (userId: number) => {
    navigate("/admin/userAddresses", {state: {userId: userId}})
  };

  const rows = items.map((item) => (
    <tr>
      <td onClick={() => onClickTable(item.id)}>{item.id}</td>
      <td onClick={() => onClickTable(item.id)}>{item.phone}</td>
      <td onClick={() => onClickTable(item.id)}>{item.name}</td>
      <td><Button onClick={() => onClickAddress(item.id)}>주소록 확인</Button></td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>회원</Title>
      <Space h="calc(1rem + 50px)" />
      <Card radius="md">
        <Flex gap="md" align={"center"}>
          <Title order={5}>조건</Title>
          <Select
            value={searchType}
            size="sm"
            withinPortal
            data={searchTypes}
            onChange={(event) => setSearchType(event!)}
          />
          <TextInput
            placeholder="검색"
            value={searchText}
            onChange={(event) => setSearchText(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button onClick={handleSearch}>검색</Button>
        </Flex>
      </Card>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>휴대번호</th>
            <th>이름</th>
            <th>주소록</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={state?.page}
        onChange={changePage}
        total={totalPage}
      />
    </>
  );
}
