import {
  Button,
  Group,
  NumberInput,
  Radio, Select,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeliveryCostService } from "../../services/DeliveryCostService";
import { DeliveryCostDto } from "../../dto/DeliveryCostDto";
import { CommonResponse } from "../../dto/CommonDto";
import { RESULT_CODE } from "../../constants/ResultCode";
import { REGION_CODE } from "../../constants/RegionCode";

const locations = [
  { value: "", label: "전체"},
  { value: "SEOUL", label: "서울" },
  { value: "INCHEON", label: "인천" },
  { value: "GYEONGGI_S", label: "경기남부" },
  { value: "GYEONGGI_N", label: "경기북부" },
];

export default function DeliveryCostDetailPage() {
  const navigate = useNavigate();
  const { deliveryCostId } = useParams();
  // const { state: { role } } = useContext(AuthContext);
  const [currentId, setCurrentId] = useState<number>(-1);
  const [regionCode, setRegionCode] = useState<string>(locations[0].value);
  const [name, setName] = useState<string>("");
  const [oneWayCost, setOneWayCost] = useState<number>(0);
  const [returnCost, setReturnCost] = useState<number>(0);
  const [firstPostcode, setFirstPostcode] = useState<string>("");
  const [lastPostcode, setLastPostcode] = useState<string>("");
  const [active, setActive] = useState<string>("true");

  useEffect(() => {
    const id = parseInt(deliveryCostId!);
    setCurrentId(id);

    if (id > 0) {
      DeliveryCostService.detail(id).then((response) => {
        const result = response.data as DeliveryCostDto;
        if (result) {
          setName(result.name);
          setRegionCode(result.region_code);
          setOneWayCost(result.oneway_cost);
          setReturnCost(result.return_cost);
          setFirstPostcode(result.first_postcode);
          setLastPostcode(result.last_postcode);
          setActive("" + result.active);
        }
        console.log(result);
      });
    }
  }, []);


  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (name === "") {
      alert("장소명을 확인해주세요.");
      return
    }

    DeliveryCostService.save({
      id: currentId,
      name: name,
      region_code: regionCode as REGION_CODE,
      oneway_cost: oneWayCost,
      return_cost: returnCost,
      first_postcode: firstPostcode,
      last_postcode: lastPostcode,
      active: active === "true"
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    DeliveryCostService.deleteCost(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          <Button onClick={onClickDelete}>삭제하기</Button>
          {/*{role === USER_ROLE.ADMIN ? (*/}
          {/*  <Button onClick={onClickDelete}>삭제하기</Button>*/}
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <Select
        placeholder="지역을 선택 해주세요."
        data={locations}
        value={regionCode}
        onChange={(event) => setRegionCode(event!)}
      />
      <Space h="xl" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="xl" />
      <NumberInput
        label="편도가격"
        value={oneWayCost}
        step={500}
        min={0}
        onChange={(value) => setOneWayCost(value ? value : 0)}
      />
      <Space h="xl" />
      <NumberInput
        label="왕복가격"
        value={returnCost}
        step={500}
        min={0}
        onChange={(value) => setReturnCost(value ? value : 0)}
      />
      <Space h="xl" />
      <TextInput
        label="우편번호 시작"
        placeholder="우편번호 시작"
        value={firstPostcode}
        onChange={(event) => setFirstPostcode(event.currentTarget.value)}
      />
      <Space h="xl" />
      <TextInput
        label="우편번호 끝"
        placeholder="우편번호 끝"
        value={lastPostcode}
        onChange={(event) => setLastPostcode(event.currentTarget.value)}
      />
      <Space h="xl" />
      <Radio.Group value={active} onChange={setActive} label="활성여부">
        <Group mt="xs">
          <Radio value="true" label="공개" />
          <Radio value="false" label="비공개" />
        </Group>
      </Radio.Group>
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
