export enum WORKER_ROLE {
  DRIVER = "DRIVER",
  ADMIN = "ADMIN",
  MASTER = "MASTER",
}

export enum WORKER_ROLE_LABELS {
  DRIVER = "배송기사",
  ADMIN = "지역관리자",
  MASTER = "총괄관리자",
}
