import {
  Button,
  Card, Container,
  Flex,
  Group,
  Pagination,
  Radio,
  SimpleGrid,
  Space,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { OrderService } from "../../services/OrderService";
import {OrderDeliveryListDto, OrderDeliveryListResponse} from "../../dto/OrderDto";
import { DELIVERY_STATUS, DELIVERY_STATUS_LABELS } from "constants/DeliveryStatus";
import { DELIVERY_TYPE_LABELS } from "../../constants/DeliveryType";
import { Utils } from "../../utils/Utils"

interface State {
  page: number;
  type: string;
  text: string;
  deliveryStatus: DELIVERY_STATUS;
}

const LIST_PAGE_LIMIT = 10;
export default function OrderDeliveryListPage() {
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const { orderId } = useParams();
  const [currentId, setCurrentId] = useState<number>(-1);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<OrderDeliveryListDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchType, setSearchType] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [state, setState] = useState<State>();

  useEffect(() => {
    let temp = state
      ? { ...state }
      : {
        page: 1,
        status: "",
        type: "",
        text: "",
        deliveryStatus: "" as DELIVERY_STATUS,
      };

    const page = searchParams.get("page");
    if (page !== null) {
      temp.page = parseInt(page);
    }

    const type = searchParams.get("type");
    if (type !== null) {
      temp.type = type;
      setSearchType(type);
    }

    const text = searchParams.get("text");
    if (text !== null) {
      temp.text = text;
      setSearchText(text);
    } else {
      setSearchText("");
    }

    const deliveryStatus = searchParams.get("deliveryStatus");
    if (deliveryStatus !== null) {
      temp.deliveryStatus = deliveryStatus as DELIVERY_STATUS;
      setDeliveryStatus(deliveryStatus);
    } else {
      setDeliveryStatus("");
    }

    setState(temp);
  }, [searchParams]);

  useEffect(() => {
    const OId = parseInt(orderId!);
    const DId = parseInt(deliveryId!);

    setCurrentId(DId);

    if (DId && state) {
      OrderService.deliveries(OId, DId, state.page, LIST_PAGE_LIMIT).then((response) => {
        const result = response.data as OrderDeliveryListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      });
    }

    else if (state) {
      OrderService.deliveryList(
        state.type,
        state.text,
        state.deliveryStatus,
        state.page,
        LIST_PAGE_LIMIT
      ).then((response) => {
        const result = response.data as OrderDeliveryListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      });
    }
  }, [state]);

  const changePage = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: "" + page });
  };

  const handleSearch = () => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...params,
      page: "1",
      type: searchType,
      text: searchText,
      deliveryStatus: deliveryStatus,
    });
  };

  const changeDeliveryRadio = (value: string) => {
    const params = Object.fromEntries(searchParams.entries());
    setDeliveryStatus(value);
    setSearchParams({ ...params, deliveryStatus: value });
  }

  const onClickTable = (deliveryId: number) => {
    navigate("" + deliveryId);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const rows = items.map((item) => (
    <tr onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.phone}</td>
      <td>{DELIVERY_STATUS_LABELS[item.status]}</td>
      <td>{DELIVERY_TYPE_LABELS[item.type]}</td>
      <td>{item.pick_address}</td>
      <td>{Utils.convertDateToYYMMDDHHmm(Utils.convertTimeToDate(item.pick_time))}</td>
      <td>{item.drop_address}</td>
      <td>{Utils.convertDateToYYMMDDHHmm(Utils.convertTimeToDate(item.drop_time))}</td>
    </tr>
  ));

  const renderHeader = () => {

    if (currentId) {
      return (
        <div>
          <Button color="gray" onClick={onClickBack}>
            뒤로가기
          </Button>
          <Space h="xl" />
          <Title order={1}>배송</Title>
          <Space h="calc(1rem + 30px)" />
        </div>
      );
    } else {
      return (
        <div>
          <Title order={1}>배송</Title>
          <Space h="calc(1rem + 30px)" />
          <Card radius="md">
            <Flex gap="lg">
              <Radio.Group
                value={deliveryStatus}
                onChange={(value) => changeDeliveryRadio(value)}
              >
                <Title order={5}>배송 상태</Title>
                <SimpleGrid cols={6}>
                  <Radio my="lg" value="" label="전체" />
                  <Radio
                    my="lg"
                    value={DELIVERY_STATUS.READY}
                    label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.READY]}
                  />
                  <Radio
                    my="lg"
                    value={DELIVERY_STATUS.ASSIGNED}
                    label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.ASSIGNED]}
                  />
                  <Radio
                    my="lg"
                    value={DELIVERY_STATUS.FIXED}
                    label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.FIXED]}
                  />
                  <Radio
                    my="lg"
                    value={DELIVERY_STATUS.ING}
                    label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.ING]}
                  />
                  <Radio
                    my="lg"
                    value={DELIVERY_STATUS.COMPLETED}
                    label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.COMPLETED]}
                  />
                </SimpleGrid>
              </Radio.Group>
              <Space w="md" />
              <Radio.Group
                value={searchType}
                onChange={(value) => setSearchType(value)}
              >
                <Title order={5}>배송 정보</Title>
                <Flex align="center">
                  <Group my="lg">
                    <Radio
                      value="name"
                      label="이름"
                    />
                    <Radio
                      value="phone"
                      label="휴대 번호"
                    />
                  </Group>
                  <TextInput
                    ml="md"
                    placeholder="입력"
                    value={searchText}
                    onChange={(event) => setSearchText(event.currentTarget.value)}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <Button onClick={handleSearch}>검색</Button>
                </Flex>
              </Radio.Group>
            </Flex>
          </Card>
        </div>
      )
    }
  }

  return (
    <>
      {renderHeader()}
      <Space h="md" />
      <Table>
        <thead>
        <tr>
          <th>ID</th>
          <th>이름</th>
          <th>휴대 번호</th>
          <th>배송 상태</th>
          <th>배송 현황</th>
          <th>픽업 주소</th>
          <th>픽업 예정 시각</th>
          <th>드랍 주소</th>
          <th>드랍 예정 시각</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={state?.page}
        onChange={changePage}
        total={totalPage}
      />
    </>
  );
}
