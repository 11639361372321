import {useContext, useState} from "react";
import {Button, Flex, TextInput} from "@mantine/core";
import styled from "styled-components";
import {ReactComponent as Logo} from "assets/img/logo.svg";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import PATH from "Path";
import {MeResponse, TokenResponse} from "dto/AuthDto";
import {Context as AuthContext} from "contexts/AuthContext";
import {WorkerService} from "services/WorkerService";
import {AuthService} from "../../services/AuthService";
import {CommonResponse} from "../../dto/CommonDto";
import Common from "../../styles/common";

const SignIn = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState<string>("");
  const [auth, setAuth] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const { updateToken } = useContext(AuthContext);

  const handleSignIn = (token: TokenResponse) => {
    WorkerService.me(token.access_token)
      .then(({ data }) => {
        const result = data as MeResponse;
        if (!result.deleted) {
          updateToken(token);
          navigate(PATH.Admin);
        } else {
          alert("정상 계정이 아닙니다.");
        }
        console.log(result);
      })
      .catch((e: AxiosError) => {
        console.log("log", e);
      });
  };

  const handleCodeAuth = (phone: string, code: string) => {
    AuthService.verifyCode(phone, code)
      .then(({ data }) => {
      const res = data as TokenResponse | CommonResponse;
      if ("result" in res) {
        alert(res.msg);
      } else {
        handleSignIn(res);
      }
      });
  };

  const handlePhoneAuth = (phone: string) => {
    AuthService.verifyPhone(phone).then(({ data }) => {
      const res = data as CommonResponse
      console.log(res);
      if (res.result > 0) {
        setAuth(true);
        alert(res.msg);
      } else {
        alert(res.msg);
      }
    })
  };

  const renderCodeBlock = () => {
    if (auth) {
      return (
        <Flex gap="xs">
          <TextInput
            placeholder="인증코드 입력"
            onChange={(event) => setCode(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key == "Enter") {
                handleCodeAuth(phone, code);
              }
            }}
          />
          <Button onClick={() => handleCodeAuth(phone, code)}>확인</Button>
        </Flex>
      )
    } else {
      return null;
    }
  }

  return (
    <Container>
      <LogoSection>
        <Logo />
      </LogoSection>
      <SignInSection>
        <Flex gap="xs">
          <TextInput
            placeholder="휴대번호 입력"
            onChange={(event) => setPhone(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key == "Enter") {
                handlePhoneAuth(phone);
              }
            }}
          />
          <Button onClick={() => handlePhoneAuth(phone)}>전송</Button>
        </Flex>
        {renderCodeBlock()}
      </SignInSection>
    </Container>
  );
};

export default {
  SignIn,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  width: 100%;
  height: 100%;
  background: white;
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
`;

const SignInSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
