import { customAxios } from "./CustomAxiosLoader";
import { UpdateUserRequest } from "dto/UserDto";

const userList = (
  searchType: string,
  searchText: string,
  page: number,
  limit: number
) => {
  return customAxios.get(`/users`, {
    params: {
      type: searchType,
      text: searchText,
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const user = (userId: number) => {
  return customAxios.get(`/users/${userId}`);
};

const save = (req: UpdateUserRequest) => {
  return customAxios.post(`/users/save`, req);
};

export const UserService = {
  save,
  user,
  userList,
};
