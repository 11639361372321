import {
  Button,
  Flex,
  Group,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserItemDto } from "dto/UserDto";
import { UserService } from "services/UserService";
import { SIGN_TYPE_LABEL } from "constants/SignType";
import { USER_STATUS, USER_STATUS_LABELS } from "constants/UserStatus";
import { Utils } from "utils/Utils";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";

export default function UserDetailPage() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [currentId, setCurrentId] = useState<number>(-1);
  const [phone, setPhone] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [info, setInfo] = useState<UserItemDto>();

  useEffect(() => {
    const id = parseInt(userId!);
    setCurrentId(id);

    if (id > 0) {
      UserService.user(id).then(({ data }) => {
        const result = data as UserItemDto;
        console.log(data);
        if (result) {
          setInfo(result);
          setName(result.name);
          setPhone(result.phone)
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    UserService.save({
      id: currentId,
      name: name,
      phone: phone,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          {/*<Button onClick={onClickDelete}>삭제하기</Button>*/}
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  if (!info) {
    return <></>;
  }

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <TextInput
        label="휴대번호"
        placeholder="휴대번호"
        value={phone}
        onChange={(event) => setPhone(event.currentTarget.value)}
      />
      <Space h="md" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="md" />
      <Text>가입일 : {Utils.convertDateToYYMMDDHHmm(Utils.convertTimeToDate(info?.created!))}</Text>
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
