import {Button, Grid, NumberInput, Select, Space, TextInput, Title,} from "@mantine/core";
import {DateTimePicker} from '@mantine/dates';
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrderRoundingDto} from "../../dto/OrderDto";
import {OrderService} from "../../services/OrderService";
import {Utils} from "utils/Utils";
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";

export default function OrderRoundingDetailPage() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { roundingId } = useParams();
  const [currentId, setCurrentId] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [place, setPlace] = useState<string>("");
  const [time, setTime] = useState<Date>(new Date());
  const [lastTime, setLastTime] = useState<Date|null>(null);

  useEffect(() => {
    const OId = parseInt(orderId!);
    const DId = parseInt(roundingId!);
    setCurrentId(DId);

    if (DId > 0) {
      OrderService.roundingDetail(OId, DId).then(({ data }) => {
        const result = data as OrderRoundingDto;
        console.log(data);
        if (result) {
          const resultLastTime = result.last_time ? Utils.convertTimeToDate(result.last_time) : null;
          setName(result.name);
          setPlace(result.place);
          setTime(Utils.convertTimeToDate(result.time));
          setLastTime(resultLastTime);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    const setLastTime = lastTime ? Utils.convertDateToTime(lastTime) : null;
    OrderService.roundingSave({
      id: currentId,
      name: name,
      place: place,
      time: Utils.convertDateToTime(time),
      last_time: setLastTime,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickModify = () => {
    handleSave();
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Button onClick={onClickModify}>수정하기</Button>
      );
    }
  };

  if (!currentId) {
    return <></>;
  }

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        뒤로가기
      </Button>
      <Space h="xl" />
      <Title>라운딩 정보</Title>
      <Space h="xl" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(e) => {setName(e.currentTarget.value)}}
      />
      <Space h="xl" />
      <TextInput
        label="장소"
        placeholder="장소"
        value={place}
        onChange={(e) => {setPlace(e.currentTarget.value)}}
      />
      <Space h="xl" />
      <DateTimePicker
        label="시작 시각"
        valueFormat="YYYY/MM/DD HH:mm"
        value={time}
        locale={"ko"}
        monthLabelFormat={"YYYY/MM"}
        onChange={(e) => setTime(e!)}
      />
      <Space h="xl" />
      <DateTimePicker
        label="종료 시각"
        valueFormat="YYYY/MM/DD HH:mm"
        value={lastTime}
        locale={"ko"}
        monthLabelFormat={"YYYY/MM"}
        onChange={(e) => setLastTime(e!)}
      />
      <Space h="xl" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
