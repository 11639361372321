import { format } from "date-fns";

const subPath = (path: string) => {
  if (path.at(0) === "/") {
    return path.substring(1, path.length);
  }
  return path;
};

const childIndex = (parentIdx: number, idx: number) => {
  return parentIdx * 100 + idx;
};

const printError = (error: any) => {
  if (error.response) {
    // Request made and server responded
    console.log("printError");
    console.log(JSON.stringify(error.response));
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
};

const convertTimeToDate = (time: number) => {
  return new Date(time * 1000);
};

const convertDateToTime = (date: Date): number => {
  return date.getTime() / 1000;
};

const convertDateToYYMMDD = (date?: Date) => {
  if (!date) {
    return "-";
  }

  return format(date, "yyyy.MM.dd");
};

const convertDateToYYMMDDHHmm = (date?: Date) => {
  if (!date) {
    return "-";
  }

  return format(date, "yyyy.MM.dd HH시 mm분");
};

const loadFromSession = <T>(key: string, def?: T) => {
  const temp = sessionStorage.getItem(key);
  if (temp) {
    return JSON.parse(temp) as T;
  }

  return def;
};

const saveToSession = <T>(key: string, obj: T) => {
  sessionStorage.setItem(key, JSON.stringify(obj));
};

const convertWon = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
}

export const Utils = {
  subPath,
  childIndex,
  printError,
  convertTimeToDate,
  convertDateToTime,
  convertDateToYYMMDD,
  convertDateToYYMMDDHHmm,
  loadFromSession,
  saveToSession,
  convertWon,
};
