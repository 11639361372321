import { customAxios } from "./CustomAxiosLoader";
import { UserAddressDetailDto } from "../dto/UserAddressDto";

const getList = (userId: number, page: number, limit: number) => {
  return customAxios.get(
    `/user_address`,
    {params: {user_id: userId, limit: limit, offset: limit * (page - 1)}}
  );
};

const detail = (id: number) => {
  return customAxios.get(`/user_address/${id}`);
}

const save = (req: UserAddressDetailDto) => {
  return customAxios.post(`/user_address/save`, req);
}

const deleteUserAddress = (id: number) => {
  return customAxios.delete(`/user_address/delete/${id}`)
}

export const UserAddressService = {
  getList,
  detail,
  save,
  deleteUserAddress,
};