import React, { useMemo, useReducer } from 'react';

export default ({ reducer, actions, defaultValue }: any) => {
  const Context = React.createContext(defaultValue);

  function Provider({ children }: any) {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions: any = {};
    // console.log(actions);

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    const value = useMemo(() => ({ state, ...boundActions }), [state]);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  return { Context, Provider };
};
