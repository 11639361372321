import axios, { AxiosError, AxiosResponse } from "axios";
import React, { useContext, useEffect, useMemo } from "react";
import { Utils } from "utils/Utils";
import { Context as AuthContext } from "contexts/AuthContext";
import { ErrorResponse, TokenResponse } from "../dto/AuthDto";
import { useNavigate } from "react-router-dom";
import { ERROR_CODE } from "constants/ErrorCode";
import PATH from "Path";
import { AuthService } from "./AuthService";

export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

function CustomAxiosLoader() {
  const {
    state: { accessToken },
    updateToken,
    signOut,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const resInterceptors = useMemo(() => {
    const onResponseError = async (error: AxiosError) => {
      if (error.response && error.response.data) {
        console.log(error.response.data);
        const res = error.response.data as ErrorResponse;
        if (error.response.status === 401) {
          if (res.error_code === ERROR_CODE.AUTH_ACCESS_TOKEN) {
            const response = await AuthService.refreshToken();
            console.log("refreshToken");
            console.log(response);
            const token = response.data as TokenResponse;
            updateToken(token);

            const prevConfig = error.config!;
            const newConfig = {
              ...prevConfig,
              headers: {
                ...prevConfig.headers,
                Authorization: `Bearer ${token.access_token}`,
              },
            };
            console.log("retry");
            console.log(newConfig);
            return customAxios(newConfig);
          } else if (
            res.error_code === ERROR_CODE.AUTH_REFRESH_TOKEN ||
            res.error_code === ERROR_CODE.ACCESS_DENIED
          ) {
            signOut();
            navigate(PATH.Home);
          }
        } else if (error.response.status === 403) {
          signOut();
          navigate(PATH.Home);
        }
      }
      Utils.printError(error);
      return Promise.reject(error);
    };

    const onResponse = async (response: AxiosResponse) => {
      // 변환 요청 관련 예외 처리.
      if (
        response.data &&
        response.data.statusCode &&
        response.data.statusCode !== 200
      ) {
        // 예외 처리 용
        console.error(response.data);
        return Promise.reject(response);
      }
      return response;
    };

    return {
      response: (response: AxiosResponse) => onResponse(response),
      error: (error: AxiosError) => onResponseError(error),
    };
  }, []); // create the interceptors

  useEffect(() => {
    console.log("useEffect CustomAxiosLoader");

    // add request interceptors
    const reqInterceptor = customAxios.interceptors.request.use(
      (config: any) => {
        // request전에 토큰 설정을 했을 경우...
        if (config.headers.Authorization) {
          return config;
        }

        console.log(config.headers["Content-Type"]);

        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      },
      (error: AxiosError) => {
        console.error(`[request error] [${JSON.stringify(error)}]`);
        return Promise.reject(error);
      }
    );

    const resInterceptor = customAxios.interceptors.response.use(
      resInterceptors.response,
      resInterceptors.error
    );

    return () => {
      customAxios.interceptors.request.eject(reqInterceptor);
      customAxios.interceptors.response.eject(resInterceptor);
    };
  }, [accessToken]);

  return <></>;
}

export default CustomAxiosLoader;
