import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Card, Flex, Pagination, Select, Space, Table, TextInput, Title} from "@mantine/core";
import {WorkerDto, WorkerListResponse} from "../../dto/WorkerDto";
import {WorkerService} from "services/WorkerService";
import {WORKER_ROLE_LABELS} from "constants/WorkerRole";

interface State {
  page: number;
  type: string;
  text: string;
}

const searchTypes = [
  { value: "name", label: "이름" },
  { value: "phone", label: "휴대번호" },
];

const LIST_PAGE_LIMIT = 10;
export default function WorkerListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<WorkerDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchType, setSearchType] = useState<string>(searchTypes[0].value);
  const [searchText, setSearchText] = useState<string>("");
  const [state, setState] = useState<State>();

  useEffect(() => {
    let temp = state
      ? { ...state }
      : {
        page: 1,
        status: "",
        type: searchTypes[0].value,
        text: "",
      };

    const page = searchParams.get("page");
    if (page !== null) {
      temp.page = parseInt(page);
    }

    const type = searchParams.get("type");
    if (type !== null) {
      temp.type = type;
      setSearchType(type);
    }

    const text = searchParams.get("text");
    if (text !== null) {
      temp.text = text;
      setSearchText(text);
    } else {
      setSearchText("");
    }

    setState(temp);
  }, [searchParams]);

  useEffect(() => {
    if (state) {
      WorkerService.workers(
        state.type,
        state.text,
        state.page,
        LIST_PAGE_LIMIT
      ).then((response) => {
        const result = response.data as WorkerListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      });
    }
  }, [state]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const changePage = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: "" + page });
  };

  const handleSearch = () => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...params,
      page: "1",
      type: searchType,
      text: searchText,
    });
  };

  const onClickTable = (userWorksId: number) => {
    navigate("" + userWorksId);
  };

  const rows = items.map((item) => (
    <tr onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.phone}</td>
      <td>{WORKER_ROLE_LABELS[item.role]}</td>
      <td>{item.deleted ? "비활성" : "활성"}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>계정 관리</Title>
      <Flex my="md" justify="flex-end" align="center" wrap="wrap">
        <Button onClick={onClickCreate}>등록하기</Button>
      </Flex>
      <Card radius="md">
        <Flex align={"center"}>
          <Title order={5}>조건</Title>
          <Select
            mx="md"
            value={searchType}
            size="sm"
            withinPortal
            data={searchTypes}
            onChange={(event) => setSearchType(event!)}
          />
          <TextInput
            placeholder="입력"
            value={searchText}
            onChange={(event) => setSearchText(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button onClick={handleSearch}>검색</Button>
        </Flex>
      </Card>
      <Space h="md" />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>휴대 번호</th>
            <th>권한</th>
            <th>활성 여부</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={state?.page}
        onChange={changePage}
        total={totalPage}
      />
    </>
  );
}
