import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Table, Title } from "@mantine/core";
import { PlaceDto, PlaceListResponse } from "../../dto/PlaceDto";
import { PlaceService } from "../../services/PlaceService";
import { Utils } from "../../utils/Utils";

const LIST_PAGE_LIMIT = 10;
export default function PlaceListPage() {
  const navigate = useNavigate();
  const [items, setItems] = useState<PlaceDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    PlaceService.getList(currentPage, LIST_PAGE_LIMIT).then((response) => {
      const result = response.data as PlaceListResponse;
      setItems(result.items);
      setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
      console.log(result);
    });
  }, [currentPage]);

  const onClickCreate = () => {
    navigate("-1");
  };

  const onClickTable = (placeId: number) => {
    navigate("" + placeId);
  };

  const rows = items.map((item) => (
    <tr key={item.id} onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.postcode}</td>
      <td>{item.address}</td>
      <td>{Utils.convertDateToYYMMDD(Utils.convertTimeToDate(item.created))}</td>
      <td>{item.active ? "활성" : "비활성"}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>장소</Title>
      <Space h="md" />
      <Flex mih={50} gap="md" justify="flex-end" align="center" wrap="wrap">
        <Button onClick={onClickCreate}>등록하기</Button>
      </Flex>
      <Space h="md" />
      <Table>
        <thead>
        <tr>
          <th>ID</th>
          <th>장소(골프장)</th>
          <th>우편번호</th>
          <th>주소</th>
          <th>등록일</th>
          <th>활성여부</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={currentPage}
        onChange={setCurrentPage}
        total={totalPage}
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
`;
