export enum DELIVERY_TYPE {
  HA = "HA", // 집 -> 공항
  AH = "AH", // 공항 -> 집
  AP = "AP", // 공항 -> 골프장
  PP = "PP", // 골프장 -> 골프장
  PA = "PA", // 골프장 -> 공항
}

export enum DELIVERY_TYPE_LABELS {
  HA = "집 -> 공항",
  AH = "공항 -> 집",
  AP = " 공항 -> 골프장",
  PP = "골프장 -> 골프장",
  PA = "골프장 -> 공항",
}
