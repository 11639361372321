import React, { Dispatch } from "react";
import createDataContext from "contexts/createDataContext";
import { TokenResponse } from "dto/AuthDto";
import { WORKER_ROLE } from "../constants/WorkerRole";

const KEY_ACCESS_TOKEN = "@access_token";
const KEY_REFRESH_TOKEN = "@refresh_token";
const KEY_USER_ROLE = "@user_role";

// 상태를 위한 타입
type State = {
  accessToken: string;
  role: WORKER_ROLE;
};

// 모든 액션들을 위한 타입
type Action =
  | { type: "SET_ACCESS_TOKEN"; token: string }
  | { type: "SET_WORKER_ROLE"; role: WORKER_ROLE };

// 리듀서
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.token,
      };
    case "SET_WORKER_ROLE":
      return {
        ...state,
        role: action.role,
      };
    default:
      throw new Error("Unhandled action");
  }
};

const resetStorage = () => {
  sessionStorage.setItem(KEY_ACCESS_TOKEN, "");
  sessionStorage.setItem(KEY_REFRESH_TOKEN, "");
  sessionStorage.setItem(KEY_USER_ROLE, "");
};

const updateToken = (dispatch: Dispatch<Action>) => {
  return (token: TokenResponse) => {
    sessionStorage.setItem(KEY_ACCESS_TOKEN, token.access_token);
    if (token.refresh_token) {
      sessionStorage.setItem(KEY_REFRESH_TOKEN, token.refresh_token);
    }
    sessionStorage.setItem(KEY_USER_ROLE, token.role);
    dispatch({
      type: "SET_ACCESS_TOKEN",
      token: token.access_token,
    });
    dispatch({
      type: "SET_WORKER_ROLE",
      role: token.role,
    });
  };
};

const signOut = (dispatch: Dispatch<Action>) => {
  return () => {
    console.log("signOut");
    try {
      resetStorage();
      dispatch({
        type: "SET_ACCESS_TOKEN",
        token: "",
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
};

export const getRefreshToken = () => {
  const token = sessionStorage.getItem(KEY_REFRESH_TOKEN);
  return token || "";
};

export const getAccessToken = () => {
  const token = sessionStorage.getItem(KEY_ACCESS_TOKEN);
  console.log("getAccessToken : " + token);
  return token || "";
};

export const getUserRole = () => {
  const role = sessionStorage.getItem(KEY_USER_ROLE);
  console.log("getUserRole : " + role);
  return role || "";
};

export const { Provider, Context } = createDataContext({
  reducer,
  actions: {
    updateToken,
    signOut,
  },
  defaultValue: {
    accessToken: getAccessToken(),
    role: getUserRole(),
  },
});
