export enum ORDER_TYPE {
  RETURN = "RETURN",
  INSIDE = "INSIDE",
  ONEWAY_JEJU = "ONEWAY_JEJU",
  ONWAY_GIMPO = "ONEWAY_GIMPO",
}

export enum ORDER_TYPE_LABELS {
  RETURN = "왕복",
  INSIDE = "골프장 이동",
  ONEWAY_JEJU = "편도(제주)",
  ONEWAY_GIMPO = "편도(김포)",
}
