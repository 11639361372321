import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html, body, #root {
    width: 100%;
    min-width: 1280px;
    height: 100%;
}

#root button {
    border: none;
}
`;

export default GlobalStyle;
