import {
  Button,
  Card,
  Grid,
  Space,
  Table,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {OrderDto, OrderDetailDto, OrderAddressDto, OrderRoundingDto} from "../../dto/OrderDto";
import { OrderService } from "../../services/OrderService";
import { Utils } from "utils/Utils";
import { ORDER_STATUS_LABELS } from "../../constants/OrderStatus";
import { ORDER_TYPE_LABELS } from "../../constants/OrderType";
import { DELIVERY_STATUS_LABELS } from "../../constants/DeliveryStatus";

export default function OrderDetailPage() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [currentId, setCurrentId] = useState<number>(-1);
  const [order, setOrder] = useState<OrderDto>();
  const [addresses, setAddresses] = useState<OrderAddressDto[]>([]);
  const [roundings, setRoundings] = useState<OrderRoundingDto[]>([]);

  useEffect(() => {
    const id = parseInt(orderId!);
    setCurrentId(id);

    if (id > 0) {
      OrderService.order(id).then(({ data }) => {
        const result = data as OrderDetailDto;
        console.log(data);
        if (result) {
          setOrder(result.order_info);
          setAddresses(result.addresses);
          setRoundings(result.roundings);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickAddressTable = (orderId: number, deliveryId: number) => {
    navigate("orderDeliveries/" + deliveryId);
  };

  const delivery_rows = addresses.map((item) => (
    <tr onClick={() => onClickAddressTable(item.order_id, item.id)}>
      <td>{item.name}</td>
      <td>{item.phone}</td>
      <td>{item.address}</td>
    </tr>
  ));

  const onClickRoundingTable = (roundingId: number) => {
    navigate("rounding/" + roundingId);
  }

  const rounding_rows = roundings.map((item) => (
    <tr onClick={() => onClickRoundingTable(item.id)}>
      <td>{item.name}</td>
      <td>{item.place}</td>
      <td>{Utils.convertDateToYYMMDDHHmm(Utils.convertTimeToDate(item.time))}</td>
      <td>{item.last_time ? Utils.convertDateToYYMMDDHHmm(Utils.convertTimeToDate(item.last_time)) : "-"}</td>
    </tr>
  ));

  if (!order) {
    return <></>;
  }

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Card radius="sm">
        <Title>주문 정보</Title>
        <Space h="xl" />
        <Grid>
          <Grid.Col fw={600} mx={5} span={1}>주문번호</Grid.Col>
          <Grid.Col span={4}>{order.uuid}</Grid.Col>
          <Grid.Col fw={600} mx={5} span={1}>주문상태</Grid.Col>
          <Grid.Col span={4}>{ORDER_STATUS_LABELS[order.status]}</Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col fw={600} mx={5} span={1}>주문자</Grid.Col>
          <Grid.Col span={4}>{order.user_name}</Grid.Col>
          <Grid.Col fw={600} mx={5} span={1}>주문금액</Grid.Col>
          <Grid.Col span={4}>{Utils.convertWon(order.amount)}</Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col fw={600} mx={5} span={1}>휴대번호</Grid.Col>
          <Grid.Col span={4}>{order.user_phone}</Grid.Col>
          <Grid.Col fw={600} mx={5} span={1}>주문타입</Grid.Col>
          <Grid.Col span={4}>{ORDER_TYPE_LABELS[order.type]}</Grid.Col>
        </Grid>
      </Card>
      <Space h="xl" />
      <Card>
        <Title>동반자 정보</Title>
        <Space h="xl" />
        <Table>
          <thead>
            <tr>
              <th>이름</th>
              <th>휴대번호</th>
              <th>주소</th>
            </tr>
          </thead>
          <tbody>{delivery_rows}</tbody>
        </Table>
      </Card>
      <Space h="xl" />
      <Card>
        <Title>라운딩 정보</Title>
        <Space h="xl" />
        <Table>
          <thead>
          <tr>
            <th>예약자명</th>
            <th>장소</th>
            <th>시작 시각</th>
            <th>종료 시각</th>
          </tr>
          </thead>
          <tbody>{rounding_rows}</tbody>
        </Table>
      </Card>
      <Space h="xl" />
      <Space h="xl" />
    </>
  );
}
