export enum DELIVERY_STATUS {
  READY = "READY",  // 배송 준비
  ASSIGNED = "ASSIGNED",  // 담당자 배정
  FIXED = "FIXED",  // 통화 후 픽업 시간 확정
  ING = "ING",  // 배송 중
  COMPLETED = "COMPLETED",  // 배송 완료
}

export enum DELIVERY_STATUS_LABELS {
  READY = "배송 준비",
  ASSIGNED = "담당자 배정",
  FIXED = "픽업 확정",
  ING = "배송 중",
  COMPLETED = "배송 완료",
}
