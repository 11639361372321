import React, { useContext } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import NavBar from "components/NavBar";
import { AppShell, Button, Flex, Header, Navbar } from "@mantine/core";
import PATH from "Path";
import { AuthRoute } from "AuthRoute";
import { WORKER_ROLE } from "constants/WorkerRole";
import { Context as AuthContext } from "contexts/AuthContext";
import UserAddressDetailPage from "./userAddress/UserAddressDetailPage";
import UserAddressListPage from "./userAddress/UserAddressListPage";
import OrderDeliveryDetailPage from "./order/OrderDeliveryDetailPage"
import OrderDeliveryListPage from "./order/OrderDeliveryListPage";
import OrderRoundingDetailPage from "./order/OrderRoundingDetailPage";
import OrderDetailPage from "./order/OrderDetailPage"
import OrderListPage from "./order/OrderListPage"
import NoticeDetailPage from "./notice/NoticeDetailPage";
import NoticeListPage from "./notice/NoticeListPage";
import DeliveryCostDetailPage from "./delivery_cost/DeliveryCostDetailPage"
import DeliveryCostListPage from "./delivery_cost/DeliveryCostListPage"
import PlaceDetailPage from "./place/PlaceDetailPage";
import PlaceListPage from "./place/PlaceListPage";
import WorkerDetailPage from "./worker/WorkerDetailPage"
import WorkerListPage from "./worker/WorkerListPage"
import UserDetailPage from "./user/UserDetailPage";
import UserListPage from "./user/UserListPage";


export default function HomePage() {
  const { signOut } = useContext(AuthContext);

  return (
    <Container
      padding="md"
      navbar={
        <Navbar width={{ base: 200 }} height="calc(100% - 60px)" p="xs">
          {<NavBar />}
        </Navbar>
      }
      header={
        <Header height={60} p="xs">
          <Flex
            gap="md"
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
            h="100%"
          >
            <Button color="red" onClick={() => signOut()}>
              로그아웃
            </Button>
          </Flex>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {
        <Routes>
          <Route
            element={
              <AuthRoute allowedRoles={[WORKER_ROLE.MASTER, WORKER_ROLE.ADMIN]} />
            }
          >
            <Route path={PATH.Users}>
              <Route index element={<UserListPage />} />
              <Route path={PATH.UserId} element={<UserDetailPage />} />
            </Route>
            <Route path={PATH.UserAddresses}>
              <Route index element={<UserAddressListPage />} />
              <Route path={PATH.UserAddressId} element={<UserAddressDetailPage />} />
            </Route>
            <Route path={PATH.Orders}>
              <Route index element={<OrderListPage />} />
              <Route path={PATH.OrderId}>
                <Route index element={<OrderDetailPage />} />
                <Route path={PATH.OrderDeliveryId}>
                  <Route index element={<OrderDeliveryListPage />} />
                  <Route path={PATH.DeliveryId} element={<OrderDeliveryDetailPage />} />
                </Route>
                <Route path={PATH.OrderRoundingId} element={<OrderRoundingDetailPage />} />
              </Route>
            </Route>
            <Route path={PATH.Deliveries}>
              <Route index element={<OrderDeliveryListPage />} />
              <Route path={PATH.DeliveryId} element={<OrderDeliveryDetailPage />} />
            </Route>
            {/*<Route path={PATH.Notices}>*/}
            {/*  <Route index element={<NoticeListPage />} />*/}
            {/*  <Route path={PATH.NoticeId} element={<NoticeDetailPage />} />*/}
            {/*</Route>*/}
            <Route path={PATH.Places}>
              <Route index element={<PlaceListPage />} />
              <Route path={PATH.PlaceId} element={<PlaceDetailPage />} />
            </Route>
          </Route>
          <Route
            element={
              <AuthRoute allowedRoles={[WORKER_ROLE.MASTER]} />
            }
          >
            <Route path={PATH.DeliveryCosts}>
              <Route index element={<DeliveryCostListPage />} />
              <Route path={PATH.DeliveryCostId} element={<DeliveryCostDetailPage />} />
            </Route>
            <Route path={PATH.Workers}>
              <Route index element={<WorkerListPage />} />
              <Route path={PATH.WorkerId} element={<WorkerDetailPage />} />
            </Route>
          </Route>
        </Routes>
      }
    </Container>
  );
}

const Container = styled(AppShell)`
  position: relative;
  width: 100%;
  height: 100%;

  & main {
    padding-top: calc(var(--mantine-header-height, 0px) + 2rem);
    padding-bottom: calc(var(--mantine-footer-height, 0px) + 2rem);
    padding-left: calc(var(--mantine-navbar-width, 0px) + 2rem);
    padding-right: calc(var(--mantine-aside-width, 0px) + 2rem);
  }
`;
