import {Button, Card, Flex, Grid, NumberInput, Select, Space, Text, TextInput, Title,} from "@mantine/core";
import {DateTimePicker} from '@mantine/dates';
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrderDeliveryDetailDto} from "../../dto/OrderDto";
import {OrderService} from "../../services/OrderService";
import {WorkerDto} from "../../dto/WorkerDto";
import {WorkerService} from "../../services/WorkerService";
import {DELIVERY_STATUS, DELIVERY_STATUS_LABELS} from "../../constants/DeliveryStatus";
import {Utils} from "utils/Utils";
import DaumPostcode from "react-daum-postcode";
import {CommonResponse} from "dto/CommonDto";
import {RESULT_CODE} from "constants/ResultCode";
import {DELIVERY_TYPE, DELIVERY_TYPE_LABELS} from "../../constants/DeliveryType";
import {REGION_CODE, REGION_CODE_LABELS} from "../../constants/RegionCode";

interface WorkerSelectData {
  value: string;
  label: string;
}

interface StatusSelectData {
  value: string;
  label: string;
}

interface TypeSelectData {
  value: string;
  label: string;
}

interface RegionSelectData {
  value: string;
  label: string;
}

export default function OrderDeliveryDetailPage() {
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const [currentId, setCurrentId] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [pickPopup, setPickPopup] = useState(false);
  const [pickName, setPickName] = useState<string>("");
  const [pickAddress, setPickAddress] = useState<string>("");
  const [pickDate, setPickDate] = useState<Date>(new Date());
  const [pickMemo, setPickMemo] = useState<string>("");
  const [dropPopup, setDropPopup] = useState(false);
  const [dropName, setDropName] = useState<string>("");
  const [dropAddress, setDropAddress] = useState<string>("");
  const [dropDate, setDropDate] = useState<Date>(new Date());
  const [dropMemo, setDropMemo] = useState<string>("");
  const [worker, setWorker] = useState<string>("0");
  const [workerList, setWorkerList] = useState<WorkerSelectData[]>([]);
  const [status, setStatus] = useState<DELIVERY_STATUS>(DELIVERY_STATUS.ING);
  const [statusList, setStatusList] = useState<StatusSelectData[]>([]);
  const [type, setType] = useState<DELIVERY_TYPE>(DELIVERY_TYPE.HA);
  const [typeList, setTypeList] = useState<TypeSelectData[]>([]);
  const [region, setRegion] = useState<REGION_CODE>(REGION_CODE.SEOUL);
  const [regionList, setRegionList] = useState<RegionSelectData[]>([]);
  const [golfBag, setGolfBag] = useState<number>(0);
  const [bostonBag, setBostonBag] = useState<number>(0);

  useEffect(() => {
    const id = parseInt(deliveryId!);
    setCurrentId(id);

    if (id > 0) {
      OrderService.deliveryDetail(id).then(({ data }) => {
        const result = data as OrderDeliveryDetailDto;
        console.log(data);
        if (result) {
          setWorker("" + result.worker_id);
          setName(result.name);
          setPhone(result.phone);
          setStatus(result.status);
          setType(result.type);
          setRegion(result.region_code);
          setPickName(result.pick_name);
          setPickAddress(result.pick_address);
          setPickDate(Utils.convertTimeToDate(result.pick_time));
          setPickMemo(result.pick_memo);
          setDropName(result.drop_name);
          setDropAddress(result.drop_address);
          setDropDate(Utils.convertTimeToDate(result.drop_time));
          setDropMemo(result.drop_memo);
          setGolfBag(result.golf_bag);
          setBostonBag(result.boston_bag);
        }
      });

      WorkerService.drivers().then(({ data }) => {
        const result = data as WorkerDto[]
        console.log(result);
        let list: WorkerSelectData[] = [{value: "", label: "미배정"}]
        for (const item of result) {
          list.push({ value: "" + item.id, label: item.name });
        }
        setWorkerList(list);
      });

      let status: StatusSelectData[] = []
      for (const key in DELIVERY_STATUS) {
        status.push({
          value: key,
          label: DELIVERY_STATUS_LABELS[key as DELIVERY_STATUS],
        });
      }
      setStatusList(status);

      let type: TypeSelectData[] = []
      for (const key in DELIVERY_TYPE) {
        type.push({
          value: key,
          label: DELIVERY_TYPE_LABELS[key as DELIVERY_TYPE],
        });
      }
      setTypeList(type);

      let region: RegionSelectData[] = []
      for (const key in REGION_CODE) {
        region.push({
          value: key,
          label: REGION_CODE_LABELS[key as REGION_CODE],
        });
      }
      setRegionList(region);
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handlePopup = (e: string) => {
    if (e == "pick") {
      setPickPopup(!pickPopup);
    } else {
      setDropPopup(!dropPopup);
    }
  }

  const handlePickAddress = (data: any) => {
    setPickAddress(data.address);
  }

  const handleDropAddress = (data: any) => {
    setDropAddress(data.address);
  }



  const handleSave = () => {
    OrderService.deliverySave({
      id: currentId,
      name: name,
      phone: phone,
      status: status,
      type: type,
      region_code: region,
      pick_name: pickName,
      pick_address: pickAddress,
      pick_memo: pickMemo,
      pick_time: Utils.convertDateToTime(pickDate),
      drop_name: dropName,
      drop_address: dropAddress,
      drop_memo: dropMemo,
      drop_time: Utils.convertDateToTime(dropDate),
      golf_bag: golfBag,
      boston_bag: bostonBag,
      worker_id: parseInt(worker)
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickModify = () => {
    handleSave();
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Button onClick={onClickModify}>수정하기</Button>
      );
    }
  };

  if (!currentId) {
    return <></>;
  }

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        뒤로가기
      </Button>
      <Space h="xl" />
      <Card>
        <Title>배송 정보</Title>
        <Space h="xl" />
        <Flex>
          <Text fz="lg">이름: {name}</Text>
          <Space w="xl" />
          <Text fz="lg">휴대 번호: {phone}</Text>
        </Flex>
      </Card>
      <Space h="xl" />
      <Grid>
        <Grid.Col span={3}>
          <Select
            label="배송 상태"
            placeholder="배송 상태"
            data={statusList}
            value={status}
            onChange={(e) => {setStatus(e as DELIVERY_STATUS)}}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            label="배송 현황"
            placeholder="배송 현황"
            data={typeList}
            value={type}
            onChange={(e) => {setType(e as DELIVERY_TYPE)}}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label="배송자"
            placeholder="배송자"
            data={workerList}
            value={worker}
            onChange={(e) => {setWorker(e!)}}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label="지역"
            placeholder="지역"
            data={regionList}
            value={region}
            onChange={(e) => {setRegion(e as REGION_CODE)}}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <NumberInput
            label="골프백 개수"
            placeholder="골프백 개수"
            value={golfBag}
            onChange={(e) => {setGolfBag(e ? e : 0)}}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <NumberInput
            label="보스턴백 개수"
            placeholder="보스턴백 개수"
            value={bostonBag}
            onChange={(e) => {setBostonBag(e ? e : 0)}}
          />
        </Grid.Col>
      </Grid>
      <Space h="xl" />
      <Grid align="flex-end">
        <Grid.Col span={2}>
          <DateTimePicker
            label="픽업 예정 시각"
            valueFormat="YYYY/MM/DD HH:mm"
            value={pickDate}
            locale={"ko"}
            monthLabelFormat={"YYYY/MM"}
            onChange={(e) => setPickDate(e!)}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            label="픽업 예약자명"
            placeholder="픽업 예약자명"
            value={pickName}
            onChange={(e) => setPickName(e.currentTarget.value)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="픽업 주소"
            placeholder="픽업 주소"
            value={pickAddress}
            onChange={(e) => setPickAddress(e.currentTarget.value)}
          />
        </Grid.Col>
        <Grid.Col span={2}><Button fullWidth onClick={() => handlePopup("pick")}>Pick up 주소찾기</Button></Grid.Col>
        {pickPopup && <Grid.Col span={12}><DaumPostcode submitMode={false} onComplete={handlePickAddress} /></Grid.Col>}
        <Grid.Col span={12}>
          <TextInput
            label="픽업 메모"
            placeholder="픽업 메모"
            value={pickMemo}
            onChange={(e) => setPickMemo(e.currentTarget.value)}
          />
        </Grid.Col>
      </Grid>
      <Space h="xl" />
      <Grid align="flex-end">
        <Grid.Col span={2}>
          <DateTimePicker
            label="드랍 예정 시각"
            valueFormat="YYYY/MM/DD HH:mm"
            value={dropDate}
            locale={"ko"}
            monthLabelFormat={"YYYY/MM"}
            onChange={(e) => setDropDate(e!)}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            label="드랍 예약자명"
            placeholder="드랍 예약자명"
            value={dropName}
            onChange={(e) => setDropName(e.currentTarget.value)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="드랍 주소"
            placeholder="드랍 주소"
            value={dropAddress}
            onChange={(e) => setDropAddress(e.currentTarget.value)}
          />
        </Grid.Col>
        <Grid.Col span={2}><Button fullWidth onClick={() => handlePopup("drop")}>Drop off 주소찾기</Button></Grid.Col>
        {dropPopup && <Grid.Col span={12}><DaumPostcode submitMode={false} onComplete={handleDropAddress} /></Grid.Col>}
        <Grid.Col span={12}>
          <TextInput
            label="드랍 메모"
            placeholder="드랍 메모"
            value={dropMemo}
            onChange={(e) => setPickMemo(e.currentTarget.value)}
          />
        </Grid.Col>
      </Grid>
      <Space h="xl" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
