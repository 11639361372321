import { customAxios } from "./CustomAxiosLoader";
import { WorkerUpdateDto } from "../dto/WorkerDto";

const me = (token?: string) => {
  if (token) {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return customAxios.get(`/me`, header);
  }

  return customAxios.get(`/me`);
};

const drivers = () => {
  return customAxios.get(`/drivers`);
};

const workers = (
  searchType: string,
  searchText: string,
  page: number,
  limit: number,
) => {
  return customAxios.get(`/workers`, {
    params: {
      type: searchType,
      text: searchText,
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const worker = (workerId: number) => {
  return customAxios.get(`/workers/${workerId}`);
}

const save = (req: WorkerUpdateDto) => {
  return customAxios.post(`/workers/save`, req);
}

const deleteWorker = (workerId: number) => {
  return customAxios.delete(`/workers/delete/${workerId}`);
}

export const WorkerService = {
  me,
  save,
  worker,
  workers,
  drivers,
  deleteWorker,
};
