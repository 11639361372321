import {Box, NavLink} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";

import {
  IconAdjustmentsAlt,
  IconBellRinging,
  IconBoxSeam,
  IconCurrencyWon,
  IconLocation,
  IconUsers,
  IconTruck
} from "@tabler/icons-react";
import PATH from "Path";
import {useLocation, useNavigate} from "react-router-dom";
import {Utils} from "utils/Utils";
import {Context as AuthContext} from "contexts/AuthContext";
import {WORKER_ROLE} from "constants/WorkerRole";

interface DataType {
  icon: any;
  label: string;
  path: string;
  roles: WORKER_ROLE[];
  children?: DataType[];
}

const originData: DataType[] = [
  {
    icon: IconUsers,
    label: "회원",
    path: PATH.Users,
    roles: [WORKER_ROLE.MASTER, WORKER_ROLE.ADMIN],
  },
  {
    icon: IconBoxSeam,
    label: "주문",
    path: PATH.Orders,
    roles: [WORKER_ROLE.MASTER, WORKER_ROLE.ADMIN],
  },
  {
    icon: IconTruck,
    label: "배송",
    path: PATH.Deliveries,
    roles: [WORKER_ROLE.MASTER, WORKER_ROLE.ADMIN],
  },
  {
    icon: IconLocation,
    label: "장소",
    path: PATH.Places,
    roles: [WORKER_ROLE.MASTER, WORKER_ROLE.ADMIN],
  },
  {
    icon: IconCurrencyWon,
    label: "가격",
    path: PATH.DeliveryCosts,
    roles: [WORKER_ROLE.MASTER],
  },
  {
    icon: IconAdjustmentsAlt,
    label: "계정 관리",
    path: PATH.Workers,
    roles: [WORKER_ROLE.MASTER],
  },
  // {
  //   icon: IconBellRinging,
  //   label: "공지사항",
  //   path: PATH.Notices,
  //   roles: [WORKER_ROLE.MASTER, WORKER_ROLE.ADMIN],
  // },
];

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState("");
  const [data, setData] = useState<DataType[]>(originData);

  const {
    state: { role },
  } = useContext(AuthContext);

  useEffect(() => {
    let newData: DataType[] = [...originData];
    newData = newData.filter((item) => item.roles.indexOf(role) >= 0);
    setData(newData);

    return () => {};
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const onClickNavi = (path: string) => {
    if (!path) {
      return;
    }

    console.log("onClickNavi : " + path);
    navigate(Utils.subPath(path));
  };

  const isActive = (path: string) => {
    if (!path) {
      return false;
    }

    return currentPath.indexOf(path) >= 0;
  };

  const renderItem = (item: DataType) => {
    if (item.children) {
      return (
        <NavLink
          key={item.label}
          active={isActive(item.path)}
          label={item.label}
          defaultOpened
          icon={<item.icon size="1rem" stroke={1.5} />}
          onClick={() => onClickNavi(item.path!)}
        >
          {item.children.map((item) => (
            <NavLink
              key={item.label}
              active={isActive(item.path)}
              label={item.label}
              icon={<item.icon size="1rem" stroke={1.5} />}
              onClick={() => onClickNavi(item.path)}
            />
          ))}
        </NavLink>
      );
    }

    return (
      <NavLink
        key={item.label}
        active={isActive(item.path)}
        label={item.label}
        icon={<item.icon size="1rem" stroke={1.5} />}
        onClick={() => onClickNavi(item.path!)}
      />
    );
  };

  return <Box w={180}>{data.map((item) => renderItem(item))}</Box>;
};

export default NavBar;
