import {
  Button,
  Group,
  Grid,
  Space,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useParams } from "react-router-dom";
import { UserAddressService } from "../../services/UserAddressService";
import { CommonResponse } from "../../dto/CommonDto";
import { RESULT_CODE } from "../../constants/ResultCode";
import { UserAddressDto } from "../../dto/UserAddressDto";

export default function UserAddressDetailPage() {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const { userAddressId } = useParams();
  const [currentId, setCurrentId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [address0, setAddress0] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [memo, setMemo] = useState<string>("");

  useEffect(() => {
    const id = parseInt(userAddressId!);
    setCurrentId(id);

    if (id > 0) {
      UserAddressService.detail(id).then((response) => {
        const result = response.data as UserAddressDto;
        if (result) {
          setName(result.name);
          setPhone(result.phone);
          setPostcode(result.postcode);
          setAddress0(result.address0);
          setAddress1(result.address1);
        }
        console.log(result);
      });
    }
  }, []);


  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    if (name === "" || postcode === "") {
      alert("장소명 또는 주소를 확인해주세요.");
      return
    }

    UserAddressService.save({
      id: currentId,
      name: name,
      phone: phone,
      postcode: postcode,
      address0: address0,
      address1: address1,
      memo: memo,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    UserAddressService.deleteUserAddress(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const handlePopup = () => {
    setPopup(!popup);
  }

  const handleComplete = (data: any) => {
    setPostcode(data.zonecode);
    setAddress0(data.address);
  }


  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <TextInput
        label="이름"
        placeholder="이름을 입력하세요"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="xl" />
      <TextInput
        label="휴대번호"
        placeholder="휴대번호를 입력하세요"
        value={phone}
        onChange={(event) => setPhone(event.currentTarget.value)}
      />
      <Space h="xl" />
      <Grid align="flex-end">
        <Grid.Col span={2}>
          <TextInput
            label="우편번호"
            disabled={true}
            value={postcode}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput
            label="주소"
            disabled={true}
            value={address0}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Button fullWidth onClick={handlePopup}>주소찾기</Button>
        </Grid.Col>
        <Grid.Col span={12}>{popup && <span><DaumPostcode submitMode={false} onComplete={handleComplete} /></span>}</Grid.Col>
      </Grid>
      <Space h="xs" />
      <TextInput
        label="상세 주소"
        placeholder="상세 주소를 입력하세요"
        value={address1}
        onChange={(event) => setAddress1(event.currentTarget.value)}
      />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}