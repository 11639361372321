const PATH = {
  Home: "/",

  Admin: "/admin",

  Users: "users",
  UserId: ":userId",

  UserAddresses: "userAddresses",
  UserAddressId: ":userAddressId",

  Orders: "orders",
  OrderId: ":orderId",
  OrderDeliveryId: "orderDeliveries/:deliveryId",
  OrderRoundingId: "rounding/:roundingId",

  Deliveries: "orderDeliveries",
  DeliveryId: ":deliveryId",

  Payments: "payments",
  PaymentId: ":paymentId",

  Places: "Places",
  PlaceId: ":placeId",

  DeliveryCosts: "deliveryCosts",
  DeliveryCostId: ":deliveryCostId",

  Workers: "Workers",
  WorkerId: ":WorkerId",

  Stats: "stats",

  Notices: "notices",
  NoticeId: ":noticeId",

  Error: "*",
};
export default PATH;
