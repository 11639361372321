import { customAxios } from "./CustomAxiosLoader";
import { PlaceDetailDto } from "../dto/PlaceDto";

const getList = (page: number, limit: number) => {
  return customAxios.get(
    `/place`,
    {params: {limit: limit, offset: limit * (page - 1)}}
  );
};

const detail = (id: number) => {
  return customAxios.get(`/place/${id}`);
}

const save = (req: PlaceDetailDto) => {
  return customAxios.post(`/place/save`, req);
}

const deletePlace = (id: number) => {
  return customAxios.delete(`/place/delete/${id}`)
}

export const PlaceService = {
  getList,
  detail,
  save,
  deletePlace,
};