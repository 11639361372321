import { customAxios } from "./CustomAxiosLoader";
import { OrderDeliveryDetailDto, OrderRoundingDto } from "../dto/OrderDto";

const orderList = (
  searchType: string,
  searchText: string,
  orderStatus: string,
  deliveryStatus: string,
  page: number,
  limit: number
) => {
  return customAxios.get(`/orders`, {
    params: {
      type: searchType,
      text: searchText,
      order_status: orderStatus,
      delivery_status: deliveryStatus,
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const order = (orderId: number) => {
  return customAxios.get(`/orders/${orderId}`);
};

const deliveryList = (
  searchType: string,
  searchText: string,
  deliveryStatus: string,
  page: number,
  limit: number
) => {
  return customAxios.get(`/orderDeliveries`, {
    params: {
      type: searchType,
      text: searchText,
      delivery_status: deliveryStatus,
      limit: limit,
      offset: limit * (page - 1),
    },
  });
};

const deliveries = (orderId: number, deliveryId: number, page: number, limit: number) => {
  return customAxios.get(`/orders/${orderId}/orderDeliveries/${deliveryId}`, {
    params: {limit: limit, offset: limit * (page -1)}
  });
}

const deliveryDetail = (id: number) => {
  return customAxios.get(`/orderDelivery/${id}`)
}

const deliverySave = (req: OrderDeliveryDetailDto) => {
  return customAxios.post(`/orderDelivery/save`, req)
}

const roundingDetail = (orderId: number, roundingId: number) => {
  return customAxios.get(`/orders/${orderId}/rounding/${roundingId}`)
}

const roundingSave = (req: OrderRoundingDto) => {
  return customAxios.post(`/orders/rounding/save`, req)
}


export const OrderService = {
  order,
  orderList,
  deliveries,
  deliveryList,
  deliveryDetail,
  deliverySave,
  roundingDetail,
  roundingSave,
};
