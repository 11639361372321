import {
  Button,
  Card,
  Flex,
  Group,
  Pagination,
  Radio,
  SimpleGrid,
  Space,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OrderService } from "../../services/OrderService";
import {OrderListDto, OrderListResponse} from "../../dto/OrderDto";
import { ORDER_STATUS, ORDER_STATUS_LABELS } from "constants/OrderStatus";
import { DELIVERY_STATUS, DELIVERY_STATUS_LABELS } from "constants/DeliveryStatus";
import { Utils } from "../../utils/Utils"

interface State {
  page: number;
  type: string;
  text: string;
  orderStats: string;
  deliveryStatus: string;
}

const LIST_PAGE_LIMIT = 10;
export default function OrderListPage() {
  const navigate = useNavigate();
  const [orderStatus, setOrderStatus] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<OrderListDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchType, setSearchType] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [state, setState] = useState<State>();

  useEffect(() => {
    let temp = state
      ? { ...state }
      : {
        page: 1,
        status: "",
        type: "",
        text: "",
        orderStats: "",
        deliveryStatus: "",
      };

    const page = searchParams.get("page");
    if (page !== null) {
      temp.page = parseInt(page);
    }

    const type = searchParams.get("type");
    if (type !== null) {
      temp.type = type;
      setSearchType(type);
    }

    const text = searchParams.get("text");
    if (text !== null) {
      temp.text = text;
      setSearchText(text);
    } else {
      setSearchText("");
    }

    const orderStatus = searchParams.get("orderStatus");
    if (orderStatus !== null) {
      temp.orderStats = orderStatus;
      setOrderStatus(orderStatus);
    } else {
      setOrderStatus("");
    }

    const deliveryStatus = searchParams.get("deliveryStatus");
    if (deliveryStatus !== null) {
      temp.deliveryStatus = deliveryStatus;
      setDeliveryStatus(deliveryStatus);
    } else {
      setDeliveryStatus("");
    }

    setState(temp);
  }, [searchParams]);

  useEffect(() => {
    if (state) {
      OrderService.orderList(
        state.type,
        state.text,
        state.orderStats,
        state.deliveryStatus,
        state.page,
        LIST_PAGE_LIMIT
      ).then((response) => {
        const result = response.data as OrderListResponse;
        setItems(result.items);
        setTotalPage(Math.ceil(result.total / LIST_PAGE_LIMIT));
        console.log(result);
      });
    }
  }, [state]);

  const changePage = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, page: "" + page });
  };

  const handleSearch = () => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...params,
      page: "1",
      type: searchType,
      text: searchText,
      orderStatus: orderStatus,
      deliveryStatus: deliveryStatus,
    });
  };

  const changeOrderRadio = (value: string) => {
    const params = Object.fromEntries(searchParams.entries());
    setOrderStatus(value);
    setSearchParams({ ...params, orderStatus: value });
  }

  const changeDeliveryRadio = (value: string) => {
    const params = Object.fromEntries(searchParams.entries());
    setDeliveryStatus(value);
    setSearchParams({ ...params, deliveryStatus: value });
  }

  const onClickTable = (orderId: number) => {
    navigate("" + orderId);
  };

  const rows = items.map((item) => (
    <tr onClick={() => onClickTable(item.id)}>
      <td>{item.id}</td>
      <td>{item.uuid}</td>
      <td>{ORDER_STATUS_LABELS[item.status]}</td>
      <td>{Utils.convertWon(item.amount)}</td>
    </tr>
  ));

  return (
    <>
      <Title order={1}>주문</Title>
      <Space h="calc(1rem + 30px)" />
      <Card radius="md">
        <Flex gap="lg">
          <Radio.Group
            value={orderStatus}
            onChange={(value) => changeOrderRadio(value)}
          >
            <Title order={5}>주문 상태</Title>
            <SimpleGrid cols={5} my="xl">
              <Radio value="" label="전체" />
              <Radio
                value={ORDER_STATUS.PAYMENT_READY}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.PAYMENT_READY]}
              />
              <Radio
                value={ORDER_STATUS.ACCOUNT_READY}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.ACCOUNT_READY]}
              />
              <Radio
                value={ORDER_STATUS.ACCOUNT_EXPIRED}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.ACCOUNT_EXPIRED]}
              />
              <Radio
                value={ORDER_STATUS.PAYMENT_COMPLETE}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.PAYMENT_COMPLETE]}
              />
              <Radio
                value={ORDER_STATUS.DELIVERY_READY}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.DELIVERY_READY]}
              />
              <Radio
                value={ORDER_STATUS.DELIVERY_ING}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.DELIVERY_ING]}
              />
              <Radio
                value={ORDER_STATUS.ORDER_COMPLETE}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.ORDER_COMPLETE]}
              />
              <Radio
                value={ORDER_STATUS.CANCEL_REQUEST}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.CANCEL_REQUEST]}
              />
              <Radio
                value={ORDER_STATUS.CANCEL_COMPLETE}
                label={ORDER_STATUS_LABELS[ORDER_STATUS.CANCEL_COMPLETE]}
              />
            </SimpleGrid>
          </Radio.Group>
          <Space w="md" />
          <Radio.Group
            value={deliveryStatus}
            onChange={(value) => changeDeliveryRadio(value)}
          >
            <Title order={5}>배송 상태</Title>
            <SimpleGrid cols={6} my="xl">
              <Radio value="" label="전체" />
              <Radio
                value={DELIVERY_STATUS.READY}
                label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.READY]}
              />
              <Radio
                value={DELIVERY_STATUS.ASSIGNED}
                label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.ASSIGNED]}
              />
              <Radio
                value={DELIVERY_STATUS.FIXED}
                label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.FIXED]}
              />
              <Radio
                value={DELIVERY_STATUS.ING}
                label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.ING]}
              />
              <Radio
                value={DELIVERY_STATUS.COMPLETED}
                label={DELIVERY_STATUS_LABELS[DELIVERY_STATUS.COMPLETED]}
              />
            </SimpleGrid>
          </Radio.Group>
        </Flex>
        <Space h="md" />
        <Title order={5}>주문 정보</Title>
        <Flex align="center">
          <Radio.Group
            value={searchType}
            onChange={(value) => setSearchType(value)}
          >
            <Group my="lg">
              <Radio
                value="uuid"
                label="주문 번호"
              />
              <Radio
                value="name"
                label="이름"
              />
              <Radio
                value="phone"
                label="휴대 번호"
              />
            </Group>
          </Radio.Group>
          <TextInput
            ml="md"
            placeholder="입력"
            value={searchText}
            onChange={(event) => setSearchText(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button onClick={handleSearch}>검색</Button>
        </Flex>
      </Card>
      <Space h="md" />
      <Table>
        <thead>
        <tr>
          <th>ID</th>
          <th>주문 번호</th>
          <th>주문 상태</th>
          <th>주문 금액</th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Space h="md" />
      <Pagination
        position="center"
        value={state?.page}
        onChange={changePage}
        total={totalPage}
      />
    </>
  );
}
