import { customAxios } from "./CustomAxiosLoader";
import { DeliveryCostDto } from "../dto/DeliveryCostDto";

const getList = (location: string, page: number, limit: number) => {
  return customAxios.get(
    `/delivery_cost`,
    {params: {location: location, limit: limit, offset: limit * (page - 1)}}
  );
};

const detail = (id: number) => {
  return customAxios.get(`/delivery_cost/${id}`);
}

const save = (req: DeliveryCostDto) => {
  return customAxios.post(`/delivery_cost/save`, req);
}

const deleteCost = (id: number) => {
  return customAxios.delete(`/delivery_cost/delete/${id}`)
}

export const DeliveryCostService = {
  getList,
  detail,
  save,
  deleteCost,
};