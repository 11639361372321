export enum REGION_CODE {
  SEOUL = "SEOUL",
  INCHEON = "INCHEON",
  GYEONGGI_S = "GYEONGGI_S",
  GYEONGGI_N = "GYEONGGI_N",
  JEJU = "JEJU",
}

export enum REGION_CODE_LABELS {
  SEOUL = "서울",
  INCHEON = "인천",
  GYEONGGI_S = "경기 남부",
  GYEONGGI_N = "경기 북부",
  JEJU = "제주",
}
