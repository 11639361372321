import {Button, Group, MultiSelect, Radio, Space, TextInput,} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {WorkerDetailDto} from "../../dto/WorkerDto";
import {WorkerService} from "services/WorkerService";
import {WORKER_ROLE, WORKER_ROLE_LABELS} from "constants/WorkerRole";
import {CommonResponse} from "dto/CommonDto";
import {RESULT_CODE} from "constants/ResultCode";
import {REGION_CODE, REGION_CODE_LABELS} from "../../constants/RegionCode";

const searchRegion = [
  { value: REGION_CODE.SEOUL, label: REGION_CODE_LABELS.SEOUL },
  { value: REGION_CODE.INCHEON, label: REGION_CODE_LABELS.INCHEON },
  { value: REGION_CODE.GYEONGGI_S, label: REGION_CODE_LABELS.GYEONGGI_S },
  { value: REGION_CODE.GYEONGGI_N, label: REGION_CODE_LABELS.GYEONGGI_N },
  { value: REGION_CODE.JEJU, label: REGION_CODE_LABELS.JEJU },
];

export default function WorkerDetailPage() {
  const navigate = useNavigate();
  const { WorkerId } = useParams();
  const [currentId, setCurrentId] = useState<number>(-1);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [values, setValues] = useState<REGION_CODE[]>([]);
  const [role, setRole] = useState<WORKER_ROLE>(WORKER_ROLE.ADMIN);
  const [deleted, setDeleted] = useState<string>("true");
  const [carNumber, setCarNumber] = useState<string>("");
  const [businessNumber, setBusinessNumber] = useState<string>("");

  useEffect(() => {
    const id = parseInt(WorkerId!);
    setCurrentId(id);

    if (id > 0) {
      WorkerService.worker(id).then(({ data }) => {
        const result = data as WorkerDetailDto;
        console.log(result);
        if (result) {
          setName(result.name);
          setPhone(result.phone);
          setRole(result.role);
          setDeleted("" + result.deleted);
          if (result.info) {
            setCarNumber(result.info.crn);
            setBusinessNumber(result.info.lpn);
          }
          let regions: REGION_CODE[] = [];
          for (const region of result.regions) {
            regions.push(region.code as REGION_CODE);
          }
          setValues(regions);
        }
      });
    }
  }, []);

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    WorkerService.save({
      id: currentId,
      name: name,
      phone: phone,
      role: role,
      regions: values,
      deleted: deleted === "true",
      crn: carNumber,
      lpn: businessNumber,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const onClickSave = () => {
    handleSave();
  };

  const onClickModify = () => {
    handleSave();
  };

  const onClickDelete = () => {
    WorkerService.deleteWorker(currentId).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      }
    });
  };

  const renderButtons = () => {
    if (currentId > 0) {
      return (
        <Group position="apart">
          <Button onClick={onClickModify}>수정하기</Button>
          <Button onClick={onClickDelete}>삭제하기</Button>
        </Group>
      );
    }

    return (
      <Group position="apart">
        <Button onClick={onClickSave}>저장하기</Button>
      </Group>
    );
  };

  return (
    <>
      <Button color="gray" onClick={onClickBack}>
        목록으로
      </Button>
      <Space h="xl" />
      <Space h="xl" />
      <TextInput
        label="이름"
        placeholder="이름"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Space h="md" />
      <TextInput
        label="휴대 번호"
        placeholder="휴대 번호"
        value={phone}
        onChange={(event) => setPhone(event.currentTarget.value)}
      />
      <Space h="md" />
      <MultiSelect
        label="지역"
        data={searchRegion}
        value={values}
        onChange={(value: REGION_CODE[]) => setValues(value)}
      />
      <Space h="md" />
      <Radio.Group
        label="권한"
        value={role}
        onChange={(value: WORKER_ROLE) => setRole(value)}
      >
        <Group mt="xs">
          <Radio
            value={WORKER_ROLE.MASTER}
            label={WORKER_ROLE_LABELS.MASTER}
          />
          <Radio
            value={WORKER_ROLE.ADMIN}
            label={WORKER_ROLE_LABELS.ADMIN}
          />
          <Radio
            value={WORKER_ROLE.DRIVER}
            label={WORKER_ROLE_LABELS.DRIVER}
          />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <Radio.Group
        label="활성여부"
        value={deleted}
        onChange={(value) => setDeleted(value)}
      >
        <Group mt="xs">
          <Radio value="false" label="활성" />
          <Radio value="true" label="비활성" />
        </Group>
      </Radio.Group>
      <Space h="md" />
      <TextInput
        label="차량 번호"
        placeholder="차량 번호"
        value={carNumber}
        onChange={(event) => setCarNumber(event.currentTarget.value)}
      />
      <Space h="md" />
      <TextInput
        label="사업자 번호"
        placeholder="사업자 번호"
        value={businessNumber}
        onChange={(event) => setBusinessNumber(event.currentTarget.value)}
      />
      <Space h="md" />
      <Space h="xl" />
      {renderButtons()}
    </>
  );
}
