import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Context as AuthContext } from "./contexts/AuthContext";
import PATH from "./Path";
import { useContext } from "react";

export const ProtectedRoute = () => {
  const {
    state: { accessToken },
  } = useContext(AuthContext);

  const location = useLocation();

  if (!accessToken) {
    return <Navigate to={PATH.Home} replace state={{ redirectTo: location }} />;
  }

  return <Outlet />;
};
